import { useAuth0 } from "@auth0/auth0-react"
import { DateTime } from "luxon"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  ApiError,
  ClientInvitation,
  ClientInvitationAcceptance,
  ClientsService,
} from "../api"
import Button from "../components/Button"
import DatePicker from "../components/DatePicker"
import { DateSectionText } from "../components/DateSectionText"
import GeoCoder from "../components/GeoCoder"
import LocationCard from "../components/LocationCard"
import LoginButtonSimple from "../components/LoginButtonSimple"
import PaymentCard from "../components/PaymentCard"
import PreferredDateCard from "../components/PreferredDateCard"
import ServicesPicker from "../components/ServicesPicker"
import StylistCard from "../components/StylistCard"
import {
  useAddress,
  useDateSelection,
  useGetCallback,
  usePaymentMethod,
  usePersistent,
  useServiceSelection,
  useStylist,
} from "../hooks"

const Accept = () => {
  const { invitationUuid } = useParams()

  // Grab local copies so we have a consistent snapshot of the auth state
  const {
    isLoading: auth0IsLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0()

  const [profile, setProfile] = useState<any>()
  const [invLoading, setInvLoading] = useState(true)
  const [inv, setInv] = useState<ClientInvitation | null>(null)
  const [notFound, setNotFound] = useState(false)

  useGetCallback(
    () => undefined,
    (okay, data) => setProfile(data),
    "/clients/profile/"
  )

  const load = useCallback(async () => {
    if (auth0IsLoading || invitationUuid == null) return

    try {
      setInv(await ClientsService.retrieveClientInvitation({ invitationUuid }))
    } catch (e) {
      if (!(e instanceof ApiError)) throw e

      if (e.status === 404) {
        setNotFound(true)
        return
      }

      if (e.status !== 401) throw e

      /* This is for Safari, which doesn't copy creds to the 2nd request, except the
         API we use doesn't expose whether a redirect was attempted, so we assume it
         was. */
      console.warn("Safari redirect (maybe)")
      try {
        setInv(
          await ClientsService.retrievePrivateClientInvitation({
            invitationUuid,
          })
        )
      } catch (e) {
        if (!(e instanceof ApiError)) throw e

        // Must not have been the Safari problem.
        loginWithRedirect({ appState: { returnTo: window.location.pathname } })
        return
      }
    }

    setInvLoading(false)
  }, [auth0IsLoading, invitationUuid, loginWithRedirect])

  useEffect(() => {
    load()
  }, [load])

  const {
    address,
    addressUnit,
    coordinates,
    isInvitationAddress,
    changeAddress,
    changeAddressUnit,
  } = useAddress({ profile: inv && profile, invitation: profile && inv })

  const { stylistIsLoading, stylistData } = useStylist(
    inv == null ? null : inv.stylist.uuid
  )

  const { countedServices, totalCostCents, totalDurationMinutes } =
    useServiceSelection({
      invitation: inv,
    })

  const [preferredDate, setPreferredDate] = usePersistent<string>(
    "prefDate",
    ""
  )

  const {
    isLoading: slotsLoading,
    slots,
    selectionIdx: slotIdx,
    setSelectionIdx: setSlotIdx,
    selectionDisabled: slotsDisabled,
  } = useDateSelection({
    stylistUuid: stylistData == null ? null : stylistData.uuid,
    durationMinutes: totalDurationMinutes,
    coordinates,
    preferredDate,
    invitation: inv,
  })

  const [notes, setNotes] = usePersistent("notes", "")

  const { paymentMethodIsLoading, paymentMethodError, paymentMethodData } =
    usePaymentMethod({ stylistUuid: inv?.stylist?.uuid })

  const canSubmit =
    invitationUuid != null &&
    address != null &&
    slots != null &&
    slots.length > 0 &&
    slotIdx != null &&
    !paymentMethodIsLoading &&
    paymentMethodData
  const navigate = useNavigate()
  const [accepting, setAccepting] = useState(false)

  async function keepOriginal() {
    if (invitationUuid == null) return
    setAccepting(true)
    await ClientsService.partialUpdateClientInvitationDeclination({
      invitationUuid,
    })
    navigate("/bookings")
  }

  async function accept() {
    if (!canSubmit) return

    setAccepting(true)

    const requestBody: ClientInvitationAcceptance = {}

    if (!isInvitationAddress) {
      requestBody.street_address = address
      if (addressUnit) requestBody.street_address_unit = addressUnit
    }
    if (!slotsDisabled) {
      requestBody.start = slots[slotIdx].start
    }
    if (notes) {
      requestBody.notes = notes
    }

    await ClientsService.partialUpdateClientInvitationAcceptance({
      invitationUuid,
      requestBody,
    })

    navigate("/bookings")
  }

  if (notFound) {
    return (
      <div className="container my-8 mx-auto max-w-7xl px-4 pb-12 text-left sm:px-6 lg:px-8">
        <h1 className="mb-6 text-3xl font-bold tracking-tight text-indigo-600">
          Invitation not found
        </h1>
        <p>
          The invitation could not be found. Perhaps you already accepted it?
        </p>
      </div>
    )
  }

  const needsAddress = !invLoading && address === ""
  const needsPreferredDate = preferredDate === ""
  const needsServices = false

  const dateState = () => {
    if (invLoading) return "loading"
    if (slotsDisabled) return "locked"
    if (!isAuthenticated) return "needsAuth"
    if (needsAddress || needsPreferredDate || needsServices) return "needsInfo"
    if (slotsLoading) return "loading"
    if (slots != null) {
      if (slots.length === 0) return "empty"
      return "loaded"
    }
    console.error("Unexpected state", { isAuthenticated, slotsLoading, slots })
    return "error"
  }

  return (
    <>
      <div className="container my-8 mx-auto max-w-7xl px-4 pb-12 text-left sm:px-6 lg:px-8">
        <h1 className="mb-6 text-3xl font-bold tracking-tight text-indigo-600">
          Appointment invitation
        </h1>
        <div className="mx-auto max-w-7xl pb-12">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <StylistCard stylistData={stylistData} loading={stylistIsLoading} />
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              {inv?.state === "adjusted_opt" && (
                <div className="bg-white px-4 pb-5 shadow sm:rounded-lg sm:p-6">
                  <fieldset>
                    <legend className="text-lg font-medium text-gray-900">
                      Accept adjustments?
                    </legend>
                    <div className="mb-6 mt-4 border-t border-gray-200 pt-4">
                      <p className="mb-3">
                        This invitation is for changes to your appointment for{" "}
                        <b>
                          {DateTime.fromISO(inv.original.start).toFormat(
                            "DDDD 'at' t ZZZZ"
                          )}
                        </b>
                        .
                      </p>

                      <p className="mb-3">
                        If you schedule and accept this invitation, it will{" "}
                        <em>replace</em> your original appointment.
                      </p>

                      <p className="mb-6">
                        If you want to keep the original appointment instead,
                        click the <q>Keep Original</q> button.
                      </p>

                      <div>
                        <Button onClick={keepOriginal} scheme="white">
                          Keep Original
                        </Button>
                      </div>
                    </div>
                  </fieldset>
                </div>
              )}
              <ServicesPicker
                readOnly={true}
                services={countedServices}
                stylist={stylistData}
                totalCostCents={totalCostCents}
                totalDurationMinutes={totalDurationMinutes}
              />
              <LocationCard
                fixedLocationAddress={stylistData?.fixed_location_address}
                fixedLocationCoordinates={
                  stylistData?.fixed_location_coordinates
                }
              />
              <div className="bg-white px-4 pb-5 shadow sm:rounded-lg sm:p-6">
                <fieldset>
                  <legend className="text-lg font-medium text-gray-900">
                    Details
                  </legend>
                  <div className="mb-6 mt-4 border-t border-gray-200 pt-4">
                    {address != null && (
                      <label className="block text-sm font-medium text-gray-700">
                        Your address
                        <GeoCoder
                          defaultValue={address}
                          disabled={
                            !isAuthenticated || isInvitationAddress || accepting
                          }
                          handleGeocoderValue={changeAddress}
                        />
                      </label>
                    )}
                  </div>
                  <div className="mb-6">
                    {addressUnit != null && (
                      <label className="block text-sm font-medium text-gray-700">
                        Unit (optional)
                        <input
                          disabled={
                            !isAuthenticated || isInvitationAddress || accepting
                          }
                          name="unit"
                          id="unit"
                          value={addressUnit}
                          onChange={e => changeAddressUnit(e.target.value)}
                          className="mt-1 block h-10 w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="Apt 2"
                        />
                      </label>
                    )}
                  </div>
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700">
                      Notes (optional)
                      <textarea
                        name="notes"
                        id="notes"
                        rows={5}
                        disabled={!isAuthenticated || accepting}
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        className="mt-1 block w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </label>
                  </div>
                </fieldset>
              </div>
              {inv?.start == null && (
                <PreferredDateCard
                  loading={stylistIsLoading || stylistData == null}
                  value={preferredDate}
                  availabilityMinDate={stylistData?.availabilityMinDate}
                  availabilityMaxDate={stylistData?.availabilityMaxDate}
                  onChange={setPreferredDate}
                />
              )}
              <div className="bg-white px-4 pb-5 shadow sm:rounded-lg sm:p-6">
                <fieldset>
                  <legend className="mb-4 w-full border-b border-gray-200 pb-4 text-lg font-medium text-gray-900">
                    Choose date
                  </legend>
                  <DateSectionText
                    needsAddress={needsAddress}
                    needsPreferredDate={needsPreferredDate}
                    needsServices={needsServices}
                    state={dateState()}
                    stylistUuid={inv?.stylist.uuid}
                  />
                  {slots != null && (
                    <DatePicker
                      disabled={!isAuthenticated || slotsDisabled || accepting}
                      loading={slotsLoading}
                      onChange={setSlotIdx}
                      slots={slots}
                      value={slotIdx === null ? undefined : slotIdx}
                    />
                  )}
                </fieldset>
              </div>
              {!isAuthenticated ? (
                <LoginButtonSimple label="Sign up to accept" signup />
              ) : (
                <>
                  <PaymentCard
                    error={paymentMethodError}
                    isLoading={paymentMethodIsLoading}
                    methodData={paymentMethodData}
                    returnUrl={window.location.toString()}
                    stylistUuid={inv?.stylist.uuid}
                    subdued
                  />
                  <button
                    className="mr-4 mt-6 block w-full items-center rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-center font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    disabled={!canSubmit || accepting}
                    onClick={accept}
                  >
                    Confirm appointment
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accept
